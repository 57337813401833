import React from "react"
import styled from "styled-components"
import { SiteInnerStyle } from "../styles/SiteInner.styled"
import FeaturedPostCard from "./FeaturedPostCard"
import MCForm from "./MCForm"


const HeroSection = styled.section`
    display: block;
    box-sizing: border-box;
    position: relative;
    padding: 60px 0px;
    background-color: #DDE3F3;
    border-style: solid; 
    border-color: #CFD8DC;
    border-width: 0px 0px 2px 0px;
    background-color: #f9f9f9;
    min-width: 100%;
`

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 0px auto;
  display: flex;
  max-width: 100%;
  @media (max-width: 1024px) {
      width: 100%;
  }
`
const Row = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
`

const USPColumn = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  min-height: 1px;
  margin: 0px 0px 0px 0px;
  padding: 0px 10px 0px 10px;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (min-width: 768px) {
    width: 59.907%;
  }

  @media (min-width: 992px) {
    padding: 0px 50px 0px 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
}
`


const H1Styled = styled.h1`
  font-size: 52px;

  @media (max-width: 767px) {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
`



export default function Hero({...props}) {


  return (
    <HeroSection>
      <SiteInnerStyle>
        <Container>
          <Row>
            <USPColumn>
              <H1Styled>Welcome to the Essential Wholesale Blog</H1Styled>
              <MCForm/>
            </USPColumn>
            <FeaturedPostCard {...props} />
          </Row>
        </Container>
      </SiteInnerStyle>
    </HeroSection>
  )
}
