import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"



const PostCard = styled.div`
  
  box-sizing: border-box;
  border: 1px solid #e7ebed;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  margin: 18px 18px 12px 18px;
  transition: 0.3s ease-in-out;
  transform: scale(1) translate3d(0, 0, 0) perspective(1px);
  transform-origin: left top;

  @media (min-width: 768px) {
      width: 40.049%;
      box-shadow: rgb(50 50 50 / 10%) 0px 0px 11px 0px;
  }

  @media (max-width: 1008px) {
    display: none;
}

  img {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`

const PostBadge = styled.div`
position: absolute;
top: 0;
background: #818a91;
color: #fff;
font-size: 12px;
padding: .6em 1.2em;
line-height: 1;
font-weight: 400;
border-radius: 999px;
text-transform: uppercase;
letter-spacing: 0.5px;
background-color: #00663E;  //EWL Green
// background-color: #6bc4c0; //EWL Blue
right: 0;
margin: 20px;
`

const InfoBlock = styled.div`
  padding: 18px 30px 48px 30px;
`

const PostTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 12px;

  a {
    text-decoration: none;
    color: #11171a;
  }

  a:hover {
    text-decoration: underline;
  }

  h2 {
    line-height: 1.5;
    font-size: 22px;
  }
`

const readMoreStyle = {
    marginBottom: '20px',
    display: 'inline-block',
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: 0,
    marginTop: '20px',
    paddingTop: '20px',
    textDecoration: 'none',
    color: '#00663e',
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '1.3',
}

export default function FeaturedPostCard({...props}) {
  return (
    <PostCard key={props.postURI}>
      <article itemScope itemType="http://schema.org/Article">
        <ImageContainer>
          <Link to={props.postURI}>
            {props.featuredImage !== undefined 
              ? <GatsbyImage alt={props.featuredImageAlt} image={props.featuredImage} /> 
              : <></>}
          </Link>
          <PostBadge>Blog</PostBadge>
        </ImageContainer>

        <InfoBlock>
          <PostTitle>
            <header>
              <h2>
                <Link to={props.postURI} itemProp="url">
                  <span itemProp="headline">{props.title}</span>
                </Link>
              </h2>
            </header>
            <Link to={props.postURI} style={readMoreStyle}>Read More »</Link>
          </PostTitle>
        </InfoBlock>
      </article>
    </PostCard>
  )
}
