import React from "react"
import '../components/pagination/styles.scss'
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import styled from "styled-components"
import ReactPaginate from 'react-paginate'

import Layout from "../components/layout/Layout"
import Seo from "../components/layout/seo"
import {SiteInner} from "../components/archive/Styles"
import Hero from "../components/hero/Hero"


/**  
 * The Blog Post Archive has different styles than the other archive pages (tag, category, author),
 * because it is also the home page. **/
 
const CardGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5rem auto;
`

const PostCard = styled.div`
  max-width: 450px;
  min-width: 300px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e7ebed;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  margin: 18px 18px 12px 18px;
  transition: 0.3s ease-in-out;
  transform: scale(1) translate3d(0, 0, 0) perspective(1px);
  transform-origin: left top;

  @media only screen and (max-width: 746px) {
    box-shadow: rgb(50 50 50 / 10%) 0px 0px 11px 0px;
    width: 100%;
  }

  @media only screen and (min-width: 747px) {
    &:hover {
      box-shadow: rgb(50 50 50 / 30%) 0px 0px 11px 0px;
      transform: translateY(-10px) scale(1) translate3d(0, 0, 0)
        perspective(1px);
      transform-origin: left top;
    }
  }

  @media only screen and (min-width: 747px) {
    width: calc((100% / 2) - 4rem);
  }

  @media only screen and (min-width: 1235px) {
    width: calc((100% / 3) - 5rem);
  }

  img {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`

const InfoBlock = styled.div`
  padding: 18px 18px 48px 18px;
`

const Categories = styled.div`
  p {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: normal;
  }

  a {
    color: #00673f;
    text-decoration: none;
  }

  a:hover {
    color: #00673f;
    text-decoration: underline;
  }
`

const PostTitle = styled.div`
  margin: 12px 0;

  a {
    text-decoration: none;
    color: #11171a;
  }

  a:hover {
    text-decoration: underline;
  }

  h2 {
    line-height: 1.4;
    font-size: 2.6rem;
    font-weight: 500;
  }
`

const Excerpt = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #444;
`


const h2Style = {
  textAlign: "center",
  paddingTop: "40px",
}

const Divider = styled.div`
  width: 100px;
  border-top: 3px solid #00663e;
  padding-bottom: 20px;
  height: 1px;
  margin: 0 auto;
`

const BlogIndex = ({
  data,
  pageContext: { pageNumber, totalPages, pageDirectory },
}) => {
  const posts = data.allWpPost.nodes;

  const image = getImage(posts[0].featuredImage?.node?.localFile);
  const featuredImage = {
    fluid: posts[0].featuredImage?.node?.localFile,
    alt: posts[0].featuredImage?.node?.alt || ``,
  };

  var props = {
    title: parse(posts[0].title),
    postURI: posts[0]?.uri,
    featuredImage: image,
    featuredImageAlt: featuredImage?.alt,
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected
    const selectedHref = (selectedPage === 0 ? '/' : `${pageDirectory}${selectedPage+1}`)
    console.log(`%c SELECTED PAGE`,`color:orange`)
    console.log(selectedPage)
    window.open(selectedHref, "_self")
};

  return (
    <Layout isHomePage>
      <Seo title="All posts" />

      {/** Hero section includes H1, email signup form, and card for featured post */}
      <Hero {...props}/>
      

      <SiteInner>
        <h2 style={h2Style}>Recent Posts</h2>
        <Divider />

        <CardGroup>
          {posts.map(post => {
            const title = post.title
            const uri = post.uri
            const image = getImage(post?.featuredImage?.node?.localFile)
            const featuredImage = {
              fluid: post?.featuredImage?.node?.localFile,
              alt: post?.featuredImage?.node?.alt || ``,
            }

            return (
              <PostCard key={uri}>
                <article itemScope itemType="http://schema.org/Article">
                  <ImageContainer>
                    <Link to={post.uri}>
                      {image !== undefined 
                        ? <GatsbyImage alt={featuredImage.alt} image={image} /> 
                        : <></>}
                    </Link>
                  </ImageContainer>

                  <InfoBlock>
                    <Categories>
                      <p>
                      {/* Maps first 3 categories to links divided by a vertical bar */}
                      {post.categories.category.slice(0, 3).map((category, index) => {
                        return (index === 0 
                                ? <span key={category.uri}><a href={category.uri}>{category.name}</a></span> 
                                : <span key={category.uri}> | <a href={category.uri}>{category.name}</a></span>)
                      })}
                      </p>
                    </Categories>

                    <PostTitle>
                      <header>
                        <h2>
                          <Link to={post.uri} itemProp="url">
                            <span itemProp="headline">{parse(title)}</span>
                          </Link>
                        </h2>
                      </header>
                    </PostTitle>

                    <section itemProp="description">
                      <Excerpt>{parse(post.excerpt)}</Excerpt>
                    </section>
                  </InfoBlock>
                </article>
              </PostCard>
            )
          })}
        </CardGroup>

        {/** 
         * Controls pagination links
         * See https://github.com/AdeleD/react-paginate#readme
         */}
        <ReactPaginate
          breakLabel=""
          disableInitialCallback="true"
          forcePage={pageNumber - 1}
          marginPagesDisplayed={0}
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={9}
          pageCount={totalPages}
          previousLabel=""
          renderOnZeroPageCount={null}
          containerClassName={"container responsive"}
          activeClassName={"active"}
        />
        
      </SiteInner>


    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          category: nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 25
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
