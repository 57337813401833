import React, {useState} from "react"
import styled from "styled-components"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const H2Styled = styled.h2`
  line-height: 1.4em;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #0066CC;
    text-decoration: underline;
  }
`

const Error = styled.p`
  color: #d95550;
  margin-bottom: 5px;
  font-size: 1.5rem;
  padding: 0 5px;
`

const HeroForm = styled.form`
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`


const SubH2Styled = styled.h2`
    margin-bottom: 20px;
    line-height: 1.4em;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;

    @media (max-width: 1024px) {
        text-align: center;
    }
`

const Input = styled.input`
    touch-action: manipulation;
    font: inherit;
    line-height: 1.4;
    vertical-align: middle;
    flex-grow: 1;
    border-radius: 4px;
    padding: 6px 16px;
    // min-height: 47px;
    height: 36px;
    font-weight: 300;
    font-size: 18px;
    border-width: 1px 1px 1px 1px;
    border-color: #C0C0C0;
    background-color: #fff;
    margin-right: calc( 10px/2 );
    margin-left: calc( 10px/2 );
    margin-bottom: 10px;

    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
    }

    @media (min-width: 768px) {
        width: 57%;
    }
`

const Button = styled.button`
    margin-right: calc( 10px/2 );
    margin-left: calc( 10px/2 );
    border-radius: 5px 5px 5px 5px;
    // background-color: #009FFD; //UI Blue
    // background-color: #00663E;  //EWL Green
    background-color: #6bc4c0; //EWL Blue
    color: #ffffff;
    // min-height: 47px;
    height: 50px;
    padding: 15px 30px;
    border: none;
    margin-bottom: 10px;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    @media (min-width: 768px) {
        width: 31.333%;
}
`
const Span = styled.span`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
`

function MCForm() {
    const [email, setEmail] = useState('')


    const _handleSubmit = (e) => {
        e.preventDefault();
        addToMailchimp(email) // listFields are optional if you are only capturing the email address.
        .then(data => {
            if (data.result === 'success') {
                // On successful form submit, hide form and show thank you message
                document.getElementById('mc-form').style.display = 'none';
                document.getElementById('thank-you-message').style.display = 'block';
            } else {
                var er = document.getElementById('error');
                er.style.display = 'block';
                er.innerHTML = data.msg;
                Promise.resolve(data)}
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
        })
      }

    return (
    <>
        {/* After successful form submission, form is hidden and thank you message is displayed */}
        <div id="mc-form">
            <H2Styled>
                Subscribe to our weekly newsletter that delivers the most <strong>practical, actionable, and trend-conscious tips for making and selling cosmetics</strong> in a quick, easy to read and reference format. Get an edge over the competition, for free.
            </H2Styled>
            <H2Styled>
                Find <a href="https://essentialwholesale.com/shop-products">premium bulk skin & hair care</a> and <a href="https://essentialwholesale.com/shop-ingredients">shop responsibly sourced ingredients</a> at <a href="https://essentialwholesale.com/">Essential</a>.
            </H2Styled>
            <Error id="error" style={{display:'none'}}></Error>
            <HeroForm method="post" name="homepage form" onSubmit={(e) => _handleSubmit(e, email)}>
                <Input
                    type="email"
                    autocomplete="email" 
                    placeholder="Email Address" 
                    size="1"
                    maxLength="350"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                <Button type="submit">
                    <Span>Join Free</Span>
                </Button>
            </HeroForm>
            <SubH2Styled>
                *Plus get instant access to a coupon that gives you <strong>$10 off shipping</strong> on your next order.
            </SubH2Styled>
        </div>
        
        {/* Thank you message displays after successful form submission */}
        <div id="thank-you-message" style={{display: 'none'}}>
            <H2Styled>
                Thank you for signing up!<br/>
                Use coupon code <strong>"WELCOME2EWL"</strong> to get $10 off shipping on your next order!<br />
                (We've also sent this code to your email.)
            </H2Styled>
        </div>
    </>
    )
}

export default MCForm
